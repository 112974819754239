import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterStore } from '../../interfaces';
import { FilterState } from '../../components/atoms/Table/Filter/Context/FilterContext.types';

const initialState: FilterStore = {
  pending: {},
  inprogress: {},
  completed: {},
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setPendingFilterSelected: (state: FilterStore, action: PayloadAction<FilterState>) => {
      state.pending = action.payload;
    },
    setInProgressFilterSelected: (state: FilterStore, action: PayloadAction<FilterState>) => {
      state.inprogress = action.payload;
    },
    setCompletedFilterSelected: (state: FilterStore, action: PayloadAction<FilterState>) => {
      state.completed = action.payload;
    },
  },
});

export const { setPendingFilterSelected, setInProgressFilterSelected, setCompletedFilterSelected } =
  filterSlice.actions;

export default filterSlice.reducer;
