import { Badge, Dialog } from '@hexa-ui/components';
import styled from 'styled-components';

const { Status } = Badge;
export const Container = styled.div``;

export const Root = styled(Dialog.Root)`
  padding: 16px;
`;

export const Close = styled(Dialog.Close)``;

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  row-gap: 18px;
  max-width: 650px;
  padding: 4px;

  overflow: hidden !important;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const ContainerRules = styled.div``;

export const RulesTitle = styled.span`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;
