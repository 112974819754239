import { FileStatus } from '../../interfaces';

export const actions = {
  files: {
    'download-button': [FileStatus.DONE, FileStatus.FAILED],
    'progress-button': [
      FileStatus.PENDING,
      FileStatus.UPLOADED,
      FileStatus.ANALYZING,
      FileStatus.ANALYZED,
      FileStatus.PROCESSING,
    ],
  },
};
