import { useDispatch, useSelector } from 'react-redux';
import { AppState, Tables } from '../../interfaces';
import {
  setPendingFilterSelected as setterPendingFilterSelected,
  setInProgressFilterSelected as setterInProgressFilterSelected,
  setCompletedFilterSelected as setterCompletedFilterSelected,
} from '../ducks/filterDuck';
import { FilterState } from '../../components/atoms/Table/Filter/Context/FilterContext.types';

export const useFilterStore = () => {
  const dispatch = useDispatch();

  const filterStored = useSelector((state: AppState) => state.filter);
  const setFilterStored = (tab: string, filter: FilterState) => {
    switch (tab) {
      case Tables.PENDING:
        dispatch(setterPendingFilterSelected(filter));
        break;
      case Tables.INPROGRESS:
        dispatch(setterInProgressFilterSelected(filter));
        break;
      case Tables.COMPLETED:
        dispatch(setterCompletedFilterSelected(filter));
        break;
      default:
        break;
    }
  };

  return { filterStored, setFilterStored };
};
