import React from 'react';
import { useIntl } from 'react-intl';
import { status as StatusMapping } from '../../../../constants/tables';
import { useFormatting } from '../../../../hooks/useFormatting';
import { Tables, TextDateParams, TextProps, TextType } from '../../../../interfaces';
import { StatusColumn } from '../../../atoms/StatusColumn';
import { CustomParagraph } from './styles';

export const Text: React.FC<TextProps<TextType>> = React.memo(({ type, params, hasEllipsis }) => {
  const { formatting } = useFormatting();
  const { value, width } = params;

  const { formatted } = formatting({
    type,
    value: value || '-',
    config: { datetimeType: (params as TextDateParams).type },
  });

  return (
    <CustomParagraph title={formatted.completed} $width={width} $hasellipsis={hasEllipsis}>
      {formatted.completed}
    </CustomParagraph>
  );
});

interface StatusProps {
  table: Tables;
  status: string;
}

export const Status: React.FC<StatusProps> = React.memo(({ table, status }) => {
  const { formatMessage } = useIntl();

  const { fontColor, borderColor, text } = Object.entries(StatusMapping[table].status).reduce(
    (acc, [key, value]) => {
      if (value.includes(status)) {
        const [fontColor, borderColor, text] = key.split(':');

        acc = { fontColor, borderColor, text };
      }

      return acc;
    },
    { fontColor: '', borderColor: '', text: '' }
  );

  if (table === Tables.PENDING) {
    return (
      <Text type="text" params={{ value: formatMessage({ id: text }), width: 15 }} hasEllipsis />
    );
  }

  return (
    <StatusColumn
      fontColor={fontColor}
      borderColor={borderColor}
      text={formatMessage({ id: text })}
    />
  );
});
