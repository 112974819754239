import { Actions } from '../../../components/organisms/Tables/Assessments/custom-columns';

import { Text } from '../../../components/organisms/Tables/custom-columns';
import { ColumnProps, FilterProps } from '../../../interfaces';

export const columns: ColumnProps[] = [
  {
    id: 'tables.pending.columns.pocName',
    accessor: 'pocName',
    customRender: (value: string) => <Text type="text" params={{ value, width: 15 }} hasEllipsis />,
  },
  {
    id: 'tables.pending.columns.accountId',
    accessor: 'accountId',
    customRender: (value: string) => <Text type="text" params={{ value, width: 10 }} hasEllipsis />,
  },
  {
    id: 'tables.pending.columns.salesAgency',
    accessor: 'salesAgency',
    customRender: (value: string) => <Text type="text" params={{ value, width: 15 }} hasEllipsis />,
  },
  {
    id: 'tables.pending.columns.salesArea',
    accessor: 'salesArea',
    customRender: (value: string) => <Text type="text" params={{ value, width: 15 }} hasEllipsis />,
  },
  {
    id: 'tables.pending.columns.customerType',
    accessor: 'customerType',
    customRender: (value: string) => <Text type="text" params={{ value, width: 15 }} hasEllipsis />,
  },
  {
    id: 'tables.pending.columns.creditLimit',
    accessor: 'creditLimit',
    customRender: (value: string) => (
      <Text type="currency" params={{ value, width: 15 }} hasEllipsis />
    ),
  },
  {
    id: 'tables.pending.columns.term',
    accessor: 'term',
    customRender: (value: string) => <Text type="days" params={{ value, width: 10 }} />,
  },
  {
    id: 'tables.pending.columns.fee',
    accessor: 'fee',
    customRender: (value: string) => <Text type="fee" params={{ value, width: 10 }} />,
  },
  {
    id: 'tables.pending.columns.recommendation',
    accessor: 'recommendation',
    customRender: (value: string) => <Text type="text" params={{ value, width: 15 }} />,
  },
  {
    id: 'tables.pending.columns.score',
    accessor: 'score',
    customRender: (value: string) => <Text type="text" params={{ value, width: 5 }} hasEllipsis />,
  },
  {
    id: 'tables.pending.columns.updatedAt',
    accessor: 'updatedAt',
    customRender: ({ value, type }) => <Text type="datetime" params={{ value, width: 15, type }} />,
  },
  {
    id: 'tables.pending.columns.actions',
    accessor: 'actions',
    disableSortBy: true,
    style: { width: 'auto' },
    permissions: ['actions.deny', 'actions.approve', 'actions.edit'],
    customRender: ({ selected, callbackFn, tag }) => (
      <Actions selected={selected} callbackFn={callbackFn} tag={tag} />
    ),
  },
];

export const filters: FilterProps[] = [
  {
    type: 'range-value',
    identifier: 'creditLimit',
    name: 'tables.filters.inputs.creditLimit.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'currency',
        identifier: 'min',
        label: 'tables.filters.inputs.creditLimit.min.label',
        placeholder: 'tables.filters.inputs.creditLimit.min.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
      {
        type: 'text',
        format: 'currency',
        identifier: 'max',
        label: 'tables.filters.inputs.creditLimit.max.label',
        placeholder: 'tables.filters.inputs.creditLimit.max.placeholder',
        affix: {
          type: 'currency',
          prefix: true,
          sufix: false,
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'term',
    name: 'tables.filters.inputs.term.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'days',
        identifier: 'min',
        label: 'tables.filters.inputs.term.min.label',
        placeholder: 'tables.filters.inputs.term.min.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.term.min.sufix',
        },
      },
      {
        type: 'text',
        format: 'days',
        identifier: 'max',
        label: 'tables.filters.inputs.term.max.label',
        placeholder: 'tables.filters.inputs.term.max.placeholder',
        affix: {
          type: 'text',
          prefix: false,
          sufix: true,
          label: 'tables.filters.inputs.term.max.sufix',
        },
      },
    ],
  },
  {
    type: 'range-value',
    identifier: 'fee',
    name: 'tables.filters.inputs.fee.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'percentage',
        identifier: 'min',
        label: 'tables.filters.inputs.fee.min.label',
        placeholder: 'tables.filters.inputs.fee.min.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
      {
        type: 'text',
        format: 'percentage',
        identifier: 'max',
        label: 'tables.filters.inputs.fee.max.label',
        placeholder: 'tables.filters.inputs.fee.max.placeholder',
        affix: {
          type: 'percentage',
          prefix: false,
          sufix: true,
        },
      },
    ],
  },
  {
    type: 'checkbox',
    name: 'tables.filters.inputs.recommendation.name',
    identifier: 'recommendation',
    title: 'tables.filters.inputs.recommendation.title',
    input: [
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'creditOffer',
        label: 'tables.filters.inputs.recommendation.labels.creditOffer',
        value: 'CREDIT_OFFER',
      },
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'creditIncrease',
        label: 'tables.filters.inputs.recommendation.labels.creditIncrease',
        value: 'CREDIT_INCREASE',
      },
      {
        type: 'checkbox',
        format: 'text',
        identifier: 'creditDecrease',
        label: 'tables.filters.inputs.recommendation.labels.creditDecrease',
        value: 'CREDIT_DECREASE',
      },
    ],
  },
  {
    type: 'range-date',
    identifier: 'updatedAt',
    name: 'tables.filters.inputs.updatedAt.name',
    title: '',
    input: [
      {
        type: 'text',
        format: 'text',
        identifier: 'startDate',
        label: 'tables.filters.inputs.updatedAt.min.label',
        placeholder: 'tables.filters.inputs.updatedAt.min.placeholder',
      },
      {
        type: 'text',
        format: 'text',
        identifier: 'endDate',
        label: 'tables.filters.inputs.updatedAt.max.label',
        placeholder: 'tables.filters.inputs.updatedAt.max.placeholder',
      },
    ],
  },
];
