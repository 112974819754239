import { Filter as FilterIcon } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { ClearAllButton } from '../../../ClearAllButton/ClearAllButton';
import { CollapseButton } from '../../../CollapseButton/CollapseButton';
import { useFilter } from '../Context/FilterContext';
import { FilterCard } from './Card/FilterCard';
import { RootProps } from './FilterRoot.types';
import { RootContainer } from './styles';
import { useEffect } from 'react';
import { useFilterStore } from '../../../../../store/hooks/filterStoreHook';
import { ActiveTabs } from '../../../../../interfaces';

export const FilterRoot: React.FC<RootProps> = ({ filters, onFilterFn, onClearFn }) => {
  const { formatMessage } = useIntl();

  const { open, counter, filtered, onApply } = useFilter();
  const { filterStored } = useFilterStore();
  const tab = sessionStorage.getItem(ActiveTabs.ACTIVE_TAB_KEY);

  useEffect(() => {
    if (filterStored[tab] && Object.keys(filterStored[tab]).length > 0) {
      for (const key in filterStored[tab]) {
        if (filterStored[tab].hasOwnProperty(key)) {
          const { value, formatted } = filterStored[tab][key];
          filtered.setter(key, { value, formatted });
        }
      }
      onApply({ state: filterStored[tab], onFilterFn, onClearFn });
    }
  }, []);

  return (
    <RootContainer data-testid="filter-root-container">
      <CollapseButton
        open={open}
        disabled={!filters.length}
        label={formatMessage({ id: 'tables.filters.buttons.filter' }, { value: counter })}
        icon={FilterIcon}
      >
        <FilterCard filters={filters} onFilterFn={onFilterFn} onClearFn={onClearFn} />
      </CollapseButton>

      {counter > 0 && <ClearAllButton />}
    </RootContainer>
  );
};
